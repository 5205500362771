import React from "react";
import Layout from "../../components/Layout";
import FoundationPage from "../../components/FoundationPage";
import { Divider } from "../../components/Divider";
import { Link } from "gatsby";

export default function TypographyPage({ location }) {
  return (
    <Layout location={location}>
      <FoundationPage
        pageTitle="Typography"
        link1title="View in Figma"
        link1url="https://www.figma.com/file/08RyCY34fSWYCxwrFWbojr/Global-Design-System-(Beta)?node-id=1039%3A20056"
        link2title="View MAF Storybook"
        link2url="https://webcomponents.maf.nielsenmedia.com/gds/?path=/docs/components-typography-text--preview"
        tocItems={[
          "Typefaces",
          "Type Scale & Weights",
          "Line Height",
          "OpenType Features",
          "Line Length",
        ]}
        description="Typography is the bedrock of great user interface design. We aim for legibility, visual hierarchy, and rhythm for all our written content."
      >
        <div className="section" id="typefaces">
          <h2>Typefaces</h2>
          <p>
            During the 2021 Nielsen rebrand, Inter was chosen as the typeface
            for Nielsen's identity and products. Inter has excellent support for
            many Cyrillic, Greek, and Latin languages and will be used in all
            languages it supports. If Inter doesn’t support a language, we will
            fall back to Google’s Noto Sans. Noto Sans has the most
            comprehensive language support of any typeface. This makes it an
            excellent fallback for our products across the world.
          </p>
          <p>
            In rare cases where a monospace typeface is needed, like for code
            snippets, we use Fira Code. It is not included by default. Please
            contact us for instructions on how to add it to your project.
          </p>
          <div className="row">
            <p className="small-heading mt-space-800">Inter *Regular</p>
            <div
              className="gds-size-900-regular mt-space-300"
              style={{ overflowWrap: "break-word" }}
            >
              ABCDEFGHIJKLMNOPQRSTUVWXYZ <br />
              abcdefghijklmnopqrstuvwxyz
              <br />
              1234567890!@#$%^&*()_+
            </div>
            <p className="small-heading mt-space-800">Inter Semibold</p>
            <div
              className="gds-size-900-semibold mt-space-300"
              style={{ overflowWrap: "break-word" }}
            >
              ABCDEFGHIJKLMNOPQRSTUVWXYZ
              <br />
              abcdefghijklmnopqrstuvwxyz
              <br />
              1234567890!@#$%^&*()_+
            </div>
            <p className="small-heading mt-space-800">Inter Bold</p>
            <div
              className="gds-size-900-bold mt-space-300"
              style={{ overflowWrap: "break-word" }}
            >
              ABCDEFGHIJKLMNOPQRSTUVWXYZ
              <br />
              abcdefghijklmnopqrstuvwxyz
              <br />
              1234567890!@#$%^&*()_+
            </div>
          </div>
          <div className="gds-size-200-regular mt-space-800">
            *Note: In Figma this is Medium weight. It's sized down to 400
            (Regular) weight on the web because of browser antialiasing.
          </div>
        </div>
        <Divider />
        <div className="section" id="type-scale-&-weights">
          <h2>Type Scale & Weights</h2>
          <p className="description mt-space-400">
            We adopted an 11-step type scale to give our designers ample options
            for building interfaces. Our base size is -size-500, which is 16px.
            We offer three weights, Regular, Semibold, and Bold to help provide
            appropriate contrast between text.
          </p>
          <div
            className="canvas mt-space-800"
            // style={{
            //   maxWidth: document.getElementById("___gatsby").offsetWidth - 684,
            // }}
          >
            <div className="col">
              <span className="gds-size-500-regular">Regular</span>
              <div className="mt-space-500 gds-size-0-regular">size-0</div>
              <div className="mt-space-100 gds-size-100-regular">size-100</div>
              <div className="mt-space-100 gds-size-200-regular">size-200</div>
              <div className="mt-space-100 gds-size-300-regular">size-300</div>
              <div className="mt-space-100 gds-size-400-regular">size-400</div>
              <div className="mt-space-100 gds-size-500-regular">size-500</div>
              <div className="mt-space-100 gds-size-600-regular">size-600</div>
              <div className="mt-space-100 gds-size-700-regular">size-700</div>
              <div className="mt-space-100 gds-size-800-regular">size-800</div>
              <div className="mt-space-100 gds-size-900-regular">size-900</div>
              <div className="mt-space-100 gds-size-1000-regular">
                size-1000
              </div>
            </div>
            <div className="col">
              <span className="gds-size-500-semibold">Semibold</span>
              <div className="mt-space-500 gds-size-0-semibold">size-0</div>
              <div className="mt-space-100 gds-size-100-semibold">size-100</div>
              <div className="mt-space-100 gds-size-200-semibold">size-200</div>
              <div className="mt-space-100 gds-size-300-semibold">size-300</div>
              <div className="mt-space-100 gds-size-400-semibold">size-400</div>
              <div className="mt-space-100 gds-size-500-semibold">size-500</div>
              <div className="mt-space-100 gds-size-600-semibold">size-600</div>
              <div className="mt-space-100 gds-size-700-semibold">size-700</div>
              <div className="mt-space-100 gds-size-800-semibold">size-800</div>
              <div className="mt-space-100 gds-size-900-semibold">size-900</div>
              <div className="mt-space-100 gds-size-1000-semibold">
                size-1000
              </div>
            </div>
            <div className="col">
              <span className="gds-size-500-bold">Bold</span>
              <div className="mt-space-500 gds-size-0-bold">size-0</div>
              <div className="mt-space-100 gds-size-100-bold">size-100</div>
              <div className="mt-space-100 gds-size-200-bold">size-200</div>
              <div className="mt-space-100 gds-size-300-bold">size-300</div>
              <div className="mt-space-100 gds-size-400-bold">size-400</div>
              <div className="mt-space-100 gds-size-500-bold">size-500</div>
              <div className="mt-space-100 gds-size-600-bold">size-600</div>
              <div className="mt-space-100 gds-size-700-bold">size-700</div>
              <div className="mt-space-100 gds-size-800-bold">size-800</div>
              <div className="mt-space-100 gds-size-900-bold">size-900</div>
              <div className="mt-space-100 gds-size-1000-bold">size-1000</div>
            </div>{" "}
          </div>
        </div>
        <Divider />

        <div className="section" id="line-height">
          <h2>Line Height</h2>
          <p className="description mt-space-400">
            The line heights for each type size have been aligned to a 4px grid.
            This helps us give us consistent visual rhythm for typography and
            surrounding elements. It also standardizes the options for padding
            and margin when laying out components and screens.
          </p>
          <div className="mt-space-600">
            <Link to="/foundations/design-tokens/#typography">
              <gds-button>View Design Tokens</gds-button>
            </Link>
          </div>
        </div>
        <Divider />
        <div className="section" id="opentype-features">
          <h2>OpenType Features</h2>
          <p className="description mt-space-400">
            Inter has excellent support for OpenType, an advanced set of
            typographic features. We utilize slashed zero, open digits, and
            monospaced numbers in our applications. Doing this maximizes
            legibility and reduces the need for multiple typefaces in our
            applications.
          </p>
          <div className="opentype-examples d-flex flex-wrap">
            <div className="mt-space-600">
              <div className="small-heading">
                With OpenType Features Enabled
              </div>
              <div className="mt-space-300 gds-size-1000-regular">
                1234567890
              </div>
              <div className="gds-size-1000-semibold">1234567890</div>
              <div className="gds-size-1000-bold">1234567890</div>
            </div>
            <div className="mt-space-600 opentype-none">
              <div className="small-heading">
                Without OpenType Features Enabled
              </div>
              <div className="mt-space-300 gds-size-1000-regular">
                1234567890
              </div>
              <div className="gds-size-1000-semibold">1234567890</div>
              <div className="gds-size-1000-bold">1234567890</div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="section" id="line-length">
          <h2>Line Length</h2>
          <p className="description mt-space-400">
            Make proper line length choices whenever working with bodies of
            text. Proper line length is essential to the visual rhythm of a
            design. Lines narrower than 50 characters require breaking to the
            following line too frequently. Lines wider than 90 characters make
            it difficult to continue to the correct line in a large body of
            text.
          </p>
          <div className="line-length-examples mt-space-800 canvas">
            <div className="col">
              <div className="mt-space-600 example">
                <p className="title">Too Narrow</p>
                <p className="regular" style={{ maxWidth: "30ch" }}>
                  Nielsen's core values are inclusion, courage, and growth.
                  These values animate our workplace and the work we do.
                  Inspired by those values, these design principles are the
                  foundation of our design system. They guide us in making
                  design decisions when building the system and our products.
                </p>
              </div>
              <div className="mt-space-600 example">
                <p className="title">Good Length</p>
                <p className="regular" style={{ maxWidth: "44ch" }}>
                  Nielsen's core values are inclusion, courage, and growth.
                  These values animate our workplace and the work we do.
                  Inspired by those values, these design principles are the
                  foundation of our design system. They guide us in making
                  design decisions when building the system and our products.
                </p>
              </div>
              <div className="mt-space-600 example">
                <p className="title">Ideal Length</p>
                <p className="regular" style={{ maxWidth: "58ch" }}>
                  Nielsen's core values are inclusion, courage, and growth.
                  These values animate our workplace and the work we do.
                  Inspired by those values, these design principles are the
                  foundation of our design system. They guide us in making
                  design decisions when building the system and our products.
                </p>
              </div>
              <div className="mt-space-600 example">
                <p className="title">Too Wide</p>
                <p className="regular" style={{ maxWidth: "72ch" }}>
                  Nielsen's core values are inclusion, courage, and growth.
                  These values animate our workplace and the work we do.
                  Inspired by those values, these design principles are the
                  foundation of our design system. They guide us in making
                  design decisions when building the system and our products.
                </p>
              </div>
              <div className="vertical-line char-0">
                <div className="char-count">0</div>
              </div>
              <div className="vertical-line char-40">
                <div className="char-count">40</div>
              </div>
              <div className="vertical-line char-60">
                <div className="char-count">60</div>
              </div>
              <div className="vertical-line char-80">
                <div className="char-count">80</div>
              </div>
              <div className="vertical-line char-99">
                <div className="char-count">99</div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
      </FoundationPage>
    </Layout>
  );
}
